import React from 'react'
import { HelmetFunc } from 'components/PageMetaData'
import { ExamplesOfProjects } from 'blocks/ExamplesOfProjects'
import { RunningLine } from 'components/RunningLine'
import { useTranslation } from 'react-i18next'
import { Layout } from 'layout/Layout'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import styled from 'styled-components'
import { backgroundColors, colors } from 'styles/colors'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { displayWidth } from 'styles/width'
import { Container } from 'components/Container'
import { IconListQuestion } from 'components/IconListQuestion'
import { graphql } from 'gatsby'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { LeftSidebar, RightSidebar } from 'components/SideBar'
import { IItem } from 'types/interfaces'

const pageMetadata = {
    uk: {
        title: 'Зразок дизайну проекту інтер`єру квартири за 99$',
        description:
            'Дизайн проект містить усі креслення для ремонту та підбору меблів - зонування, розстановка меблів та сантехніки, електрика, освітлення, підлоги, стелі та ін.',
    },
    ru: {
        title: 'Образец дизайн проекта интерьера квартиры за 99$',
        description:
            'Дизайн проект содержит все чертежи для ремонта и подбора мебели - зонирование, расстановка мебели и сантехники, электрика, освещение, полы, потолки и др.',
    },
    en: {
        title: 'Sample apartment interior design project for $99',
        description:
            'The design project contains all the drawings for the repair and selection of furniture - zoning, arrangement of furniture and plumbing, electrical, lighting, floors, ceilings, etc.',
    },
}

const ProjectStructureWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: 1px solid ${colors.dark};
    }
    ${mobileAfterBorder}
`

const IconListStyled = styled(IconListQuestion)`
    background-color: ${(props) =>
        props.background || backgroundColors.contact};
    border-bottom: 1px solid ${colors.dark};
    h2 {
        font-size: 30px;
        line-height: 35px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
    }
`
const ExamplePage = ({
    data,
}: {
    data: {
        allProjectStructureDesignYaml: { items: IItem }
        allExampleFaqYaml: { items: IItem }
    }
}) => {
    const { t, i18n } = useTranslation()

    const projectStructureData = getDataByLanguage(
        data.allProjectStructureDesignYaml,
        i18n.language
    )

    const faqData = getDataByLanguage(data.allExampleFaqYaml, i18n.language)

    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <ExamplesOfProjects />
            <ProjectStructureWrapper>
                <LeftSidebar background={backgroundColors.project} />
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <IconListStyled
                        items={faqData.items}
                        fill={colors.accentText}
                        background={backgroundColors.project}
                    />
                </Container>
                <RightSidebar />
            </ProjectStructureWrapper>
            <RunningLine>{t('designProject99')}</RunningLine>
            <ProjectStructureWrapper>
                <LeftSidebar background={backgroundColors.promotion} />
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <IconListStyled
                        items={projectStructureData.items}
                        fill={colors.blue}
                    />
                </Container>
                <RightSidebar />
            </ProjectStructureWrapper>
            <DefaultFormBlock
                withPhoneMobile
                tracking={{
                    conversionType: 'FormExample',
                    eventCategory: 'FormExample',
                }}
            />
        </Layout>
    )
}

export default ExamplePage

export const query = graphql`
    query {
        allProjectStructureDesignYaml {
            edges {
                node {
                    items {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allExampleFaqYaml {
            edges {
                node {
                    items {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
